const state = {
    plannerDate: localStorage.getItem("plannerDate") || new Date(),
    plannerClientDepotName: localStorage.getItem("plannerClientDepotName") || "",
  };

  const mutations = {
    //add mutation for selectedClientDepotName
    setPlannerClientDepotName(state, data) {
      state.plannerClientDepotName = data.plannerClientDepotName;
    },
    setPlannerDate(state, data) {
      state.plannerDate = data.plannerDate;
    },
  };

  const actions = {
    setPlannerClientDepotName({ commit }, data) {
      commit("setPlannerClientDepotName", {
        plannerClientDepotName: data.plannerClientDepotName,
      });
      localStorage.setItem("plannerClientDepotName", data.plannerClientDepotName);
    },
    setPlannerDate({ commit }, data) {
      commit("setPlannerDate", {
        plannerDate: data.plannerDate,
      });
      localStorage.setItem("plannerDate", data.plannerDate.toString());
    },
  };

  const getters = {
    // add getters for selectedClientDepotName
    plannerClientDepotName(state) {
      return state.plannerClientDepotName;
    },
    plannerDate(state) {
      return state.plannerDate;
    },
  };

  export default {
    state,
    mutations,
    actions,
    getters,
  };
