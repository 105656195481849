import axios from "axios";
import router from "../router";
import { JwtPayload, decode } from "jsonwebtoken";

const state = {
  username: null,
  token: null,
  role: null,
  DepotName: null,
};

const mutations = {
  storeUser(state, userData) {
    state.username = userData.username;
    state.token = userData.token;
    state.role = userData.role;
    if (userData.DepotName) {
      state.DepotName = userData.DepotName;
    }
  },
  clearAuthData(state) {
    state.username = null;
    state.token = null;
    state.role = null;
    state.DepotName = null;
  },
};

const actions = {
  setLogoutTimer({ commit, dispatch }, expirationTime) {
    setTimeout(() => {
      dispatch("logout");
      delete axios.defaults.headers["vht-auth"];
    }, expirationTime * 1000);
  },
  login({ commit, dispatch }, data) {
    const authData = decode(data.token) as JwtPayload;
    if (authData != null && authData.exp && authData.iat) {
      const expiry = authData.exp - authData.iat;

      localStorage.setItem("token", data.token);
      localStorage.setItem("username", authData.username);
      localStorage.setItem("role", authData.role);
      localStorage.setItem("expirationDate", authData.exp.toString());
      if (authData.DepotName) {
        localStorage.setItem("DepotName", authData.DepotName);
      }
      axios.defaults.headers["vht-auth"] = data.token;
      commit("storeUser", {
        username: authData.username,
        role: authData.role,
        token: data.token,
      });
      if (authData.role == "admin") router.replace("/admindashboard");
      else if (authData.role == "user") {
        router.replace("/planner");
      } else if (authData.role == "client") {
        router.replace("/client_portal/dashboard");
      } else if (authData.role == "contractor") {
        router.replace("/contractor_portal/dashboard");
      } else {
        router.replace("/");
      }
      dispatch("setLogoutTimer", expiry);
    }
  },
  tryAutoLogin({ commit }) {
    const token = localStorage.getItem("token");
    if (token == null || token == "null") {
      return;
    }
    const expirationDate = localStorage.getItem("expirationDate");
    if (expirationDate == null || expirationDate == "null") {
      return;
    }
    const expiry = parseInt(expirationDate);
    const now = Date.now() / 1000;
    if (now >= expiry) {
      return;
    }
    const username = localStorage.getItem("username");
    const role = localStorage.getItem("role");
    const DepotName = localStorage.getItem("DepotName");
    commit("storeUser", {
      username: username,
      token: token,
      role: role,
      DepotName: DepotName,
    });
    axios.defaults.headers["vht-auth"] = token;
    return;
  },
  logout({ commit }) {
    commit("clearAuthData");
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("role");
    localStorage.removeItem("DepotName");
    router.replace("/login");
    delete axios.defaults.headers["vht-auth"];
  },
};

const getters = {
  user(state) {
    return state;
  },
  isAuthenticated: (state) => (now) => {
    if (state.token !== null) {
      const authData = decode(state.token) as JwtPayload;
      if (authData != null && authData.exp) {
        if (now < authData.exp) {
          return true;
        }
      }
    }
    return false;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
