<template>
  <v-container grid-list-md id="planner" fluid>
    <v-card>
      <v-card-title class="headline pa-3" title--text>
        <v-row class="pa-3" justify-space-around>

          <v-col sm="3" md="3" lg="1" xl="1"> Planner </v-col>
          <v-col sm="12" md="12" lg="4" xl="4">
            <v-btn color="blue" icon @click="weekDown">
              <v-icon>arrow_back_ios</v-icon>
            </v-btn>
            {{ startDate }} - {{ endDate }}
            <v-btn color="blue" icon @click="weekUp">
              <v-icon>arrow_forward_ios</v-icon>
            </v-btn>
          </v-col>
          <v-col sm="6" md="2" lg="2" xl="1">
            <v-btn large @click="resetDay" color="light-blue accent-1">
              This Week
              <v-icon right>insert_invitation</v-icon>
            </v-btn>
          </v-col>
          <v-col sm="6" md="2" lg="1" xl="1">
            <v-btn v-if="!availableContractorView" large color="green accent-1" @click="downloadJobsCSV()">
              CSV
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-col>
          <v-col sm="3" md="3" lg="2" xl="2">
            <v-switch v-model="availableContractorView" inset label="Available Contractors"></v-switch>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row class="pa-3" justify-space-around>
          <v-col sm="12" md="12" lg="12" xl="12">
            <v-progress-circular
            v-if="loadClientsInProgress"
            :indeterminate="loadClientsInProgress"
            color="primary"
          ></v-progress-circular>
            <v-btn-toggle v-if="!loadClientsInProgress" mandatory borderless v-model="selectedClientDepotName">
              <v-btn :value="client.DepotName" :key="client.DepotName" class="text-lg-caption text-xl-body-1"
                color="blue darken-4" v-for="client in clients" text> <span class="hidden-sm-and-down">{{ client.DepotName
                  + '('
                  + client.jobCount + ')' }}</span></v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-layout v-if="selectedClientDepotName && !availableContractorView" row wrap>
          <job-list v-for="day in days" :key="day" :client="selectedClientDepotName" :title="day"></job-list>
        </v-layout>
        <v-layout v-if="availableContractorView" row wrap>
          <available-contractors v-for="day in days" :key="day" :client="selectedClientDepotName"
            :title="day"></available-contractors>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import JobList from "./JobList.vue";
import AvailableContractors from "./AvailableContractors.vue";

export default {
  components: {
    jobList: JobList,
    availableContractors: AvailableContractors
  },

  data() {
    return {
      today: null,
      day: 0,
      selectedDay: new Date(),
      jobs: [],
      clients: [],
      selectedClientDepotName: "",
      toggle_one: 0,
      errors: [],
      text: "center",
      interval: 0,
      loadClientsInProgress: false,
      loadClientsRequest: 0,
      availableContractorView: false
    };
  },

  created() {
    if (this.$store.getters.plannerClientDepotName) {
      this.selectedClientDepotName = this.$store.getters.plannerClientDepotName;
    }
    if (this.$store.getters.plannerDate) {
      this.selectedDay = new Date(this.$store.getters.plannerDate);
      this.today = new Date(this.$store.getters.plannerDate);
      this.loadClients();
    } else {
      this.today = new Date();
      this.selectedDay = new Date();
      this.loadClients();
    }
    this.day = this.selectedDay.getDay();
  },

  // mounted() {
  //   this.loadClients();
  //   this.interval = setInterval(function () {
  //     this.loadClients();
  //   }.bind(this), 30000);
  // },
  // beforedestroy() {
  //   clearInterval(this.interval)
  // },

  watch: {
    selectedClientDepotName(newVal) {
      this.$store.dispatch("setPlannerClientDepotName", { plannerClientDepotName: newVal });
    },
  },

  computed: {
    startDate: function () {
      let first = this.today.getDate() - this.today.getDay() + 1; // First day is the day of the month - the day of the week
      let startDate = new Date(this.today);
      startDate.setDate(first);

      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      var day = startDate.getDate();
      var monthIndex = startDate.getMonth();
      var year = startDate.getFullYear();

      return day + " " + monthNames[monthIndex] + " " + year;
    },

    endDate: function () {
      let last = this.today.getDate() - this.today.getDay() + 7; // First day is the day of the month - the day of the week
      let endDate = new Date(this.today);
      endDate.setDate(last);
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      var day = endDate.getDate();
      var monthIndex = endDate.getMonth();
      var year = endDate.getFullYear();

      return day + " " + monthNames[monthIndex] + " " + year;
    },

    days: function () {
      let days = [];
      for (let i = 1; i < 8; i++) {
        //i = i == 7 ? 0 : i;
        let first = this.today.getDate() - this.today.getDay(); // First day is the day of the month - the day of the week
        let date = new Date(this.today);
        date.setDate(first + i);

        var weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        var monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var weekday = date.getDay();
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        days.push(
          weekdays[weekday] + ", " + this.pad(day, 2) + " " + monthNames[monthIndex] + " " + year
        );
      }
      return days;
    },
    selectedDayDisplay: function () {
      let options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "2-digit",
      };
      return this.selectedDay.toLocaleDateString("au-EG", options);
    },
  },

  methods: {
    resetDay() {
      this.clients = [];
      this.today = new Date();
      this.selectedDay = new Date();
      this.day = new Date().getDay();
      this.$store
        .dispatch("setPlannerDate", { plannerDate: this.today })
        .then(() => {
          this.loadClients();
        });
    },

    weekUp() {
      this.today = new Date(this.today.setDate(this.today.getDate() + 7));
      this.$store
        .dispatch("setPlannerDate", { plannerDate: this.today })
        .then(() => {
          this.loadClients();
        });
    },

    weekDown() {
      this.today = new Date(this.today.setDate(this.today.getDate() - 7));
      this.$store
        .dispatch("setPlannerDate", { plannerDate: this.today })
        .then(() => {
          this.loadClients();
        });
    },
    sqlDate(dateString) {
      let date = new Date(dateString);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      return year + "-" + month + "-" + day;
    },


    loadClients() {
      this.cancelLoadClients();
      let axiosSource = axios.CancelToken.source();
      this.loadClientsRequest = { cancel: axiosSource.cancel };
      this.loadClientsInProgress = true;
      axios
        .get(
          "/job/clients?startDate=" + this.sqlDate(this.startDate) + "&endDate=" + this.sqlDate(this.endDate), { cancelToken: axiosSource.token, }
        )
        .then((response) => {
          this.clients = response.data;
          const found = this.clients.some(client => client.DepotName === this.selectedClientDepotName);
          if (!found) {
            if (this.clients.length > 0) {
              this.selectedClientDepotName = this.clients[0].DepotName;
            }
            else {
              this.selectedClientDepotName = "";
            }
          }
          this.loadClientsInProgress = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loadClientsInProgress = false;
        });
    },
    cancelLoadClients() {
      if (this.loadClientsRequest) {
        this.loadClientsRequest.cancel();
      }

    },
    pad(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
    downloadJobsCSV() {
      this.loadingCSV = true;
      axios({
        method: "get",
        url:
          "/job/download?startDate=" +
          this.sqlDate(this.startDate) +
          "&endDate=" +
          this.sqlDate(this.endDate),
        responseType: "blob",
      })
        .then((response) => {
          this.forceCSVDownload(response, this.selectedCompanyID);
          this.loadingCSV = false;
        })
        .catch((err) => {
          console.log(err + " occured");
          this.loadingCSV = false;
        });
    },
    forceCSVDownload(response, companyID) {
      const url = window.URL.createObjectURL(
        new Blob(["\ufeff", response.data])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Joblist-" + this.sqlDate + ".csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
  },
};

Date.prototype.getWeekDay = function () {
  var weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return weekday[this.getDay()];
};

Date.prototype.getMonthName = function () {
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[this.getMonth()];
};
</script>

<style scoped></style>
